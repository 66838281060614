import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'form', 'date', 'timeframe' ];

  fetchForm(event) {
    const url = event.currentTarget.dataset.url;
    fetch(url)
      .then(response => response.text())
      .then((data) => {
        this.formTarget.innerHTML = data;
        $('#booking').modal('show');
      });
  }

  showRemainingSpots(event) {
    const target = event.currentTarget;
    const timeframe = this.timeframeTarget.value;
    const date = this.dateTarget.value;
    if(!timeframe || !date) return;

    const url = `${target.dataset.url}?datetime=${date}&timeframe=${timeframe}`;
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        const spots = data.remaining_spots === 1 ? 'spot' : 'spots';
        const time = timeframe.match(/morning|afternoon/) ? `in the ${timeframe}` : 'for the whole day';
        this.timeframeTarget.nextElementSibling.innerHTML = `${data.remaining_spots} ${spots} remaining ${time}`;
      });
  }
}
