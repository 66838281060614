import { Controller } from 'stimulus';
import { initTippy } from './../packs/components/init_tippy';

export default class extends Controller {
  update(event) {
    const xhr = event.detail[2];
    this.element.innerHTML = xhr.response;
    initTippy();
  }
}
