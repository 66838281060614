import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'signIn', 'spinner' ];
  disableSignInCta() {
    this.signInTarget.disabled = true;
    this.signInTarget.classList.add('text-muted');
    this.spinnerTarget.classList.remove('d-none');
  }
}
