import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'cta', 'modal', 'times', 'ctaWrapper', 'event' ];

  fetchForm(event) {
    this.constructor.toggleModal('hide');
    const url = event.currentTarget.dataset.url;
    fetch(url)
      .then(response => response.text())
      .then((data) => {
        this.modalTarget.innerHTML = data;
        this.constructor.toggleModal('show');
      });
  }

  toggleTime(event) {
    this.timesTarget.classList.toggle('d-none', event.currentTarget.checked);
  }

  loadEvents(event) {
    const url = event.currentTarget.dataset.url;
    fetch(url)
      .then(response => response.text())
      .then((data) => {
        this.modalTarget.innerHTML = data;
        this.constructor.toggleModal('show');
      })
  }

  closeModal(event) {
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    this.constructor.toggleModal('hide');
    document.querySelectorAll('.alert').forEach(alert => alert.remove());
    document.body.insertAdjacentHTML('afterbegin', response.flash);
    const target = this.ctaWrapperTargets.find(target => +target.dataset.workDayId === response.work_day_id);
    target.outerHTML = response.cta;
  }

  showErrors(event) {
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    this.modalTarget.innerHTML = response.form;
  }

  removeEvent(event) {
    const id = event.currentTarget.dataset.id;
    const workDayId = event.currentTarget.dataset.workDayId;
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    const wrapper = this.ctaWrapperTargets.find(target => target.dataset.workDayId === workDayId);
    wrapper.outerHTML = response.cta;
    this.modalTarget.innerHTML = response.events;
  }

  static toggleModal(action) {
    $('#event').modal(action)
  }
}
