// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap";
import 'tippy.js/themes/light-border.css';
import 'tippy.js/dist/tippy.css';
import "controllers"
import { initTippy } from './components/init_tippy';

document.addEventListener('turbolinks:load', () => {
  initTippy();
});

