import { Controller } from 'stimulus';
import { initTippy } from './../packs/components/init_tippy';

export default class extends Controller {
  updateWorkDay(event) {
    const xhr = event.detail[2];
    this.element.outerHTML = xhr.response;
    initTippy();
  }
}
