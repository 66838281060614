import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'picker' ];

  updateColor() {
    const data = new FormData();
    data.append('color', this.pickerTarget.value);
    const railsToken = document.querySelector('meta[name=csrf-token]').content;
    fetch(this.data.get('url'), {
      method: 'PATCH',
      body: data,
      headers: {
        "X-CSRF-Token": railsToken,
      },

    }).then(response => response.text())
    .then((data) => {
      this.element.outerHTML = data;
    });
  }
}
