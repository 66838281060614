import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'dropdownCta', 'spinner', 'unassignedStaff' ];

  fetchUnassignedStaff() {
    this.spinnerTarget.classList.remove('d-none');
    this.unassignedStaffTarget.innerHTML = '';
    fetch(this.data.get('url'))
      .then(response => response.json())
      .then((data) => {
        this.spinnerTarget.classList.add('d-none');
        if (data.staff && data.size > 0) {
          this.unassignedStaffTarget.innerHTML =  data.staff;
        } else if (data.teachers && data.size > 0) {
          this.unassignedStaffTarget.innerHTML = data.teachers;
        } else {
          this.unassignedStaffTarget.innerHTML = `<small class="text-muted mx-2"><em>No ${data.staff ? 'staff' : 'teachers'} available</em></small>`;
        }
      });
  }
}
